import { useState, useEffect } from "react";
export default function Toaster({toastList}) {
    const [list, setList] = useState(toastList);

    useEffect(() => {
        setList([...toastList]);
        // eslint-disable-next-line
    }, [toastList]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (toastList.length && list.length) {
                deleteToast(toastList[0].id);
            }
        }, 1000);
        
        return () => {
            clearInterval(interval);
        }

        // eslint-disable-next-line
    }, [toastList, list]);

    const deleteToast = id => {
        const listItemIndex = list.findIndex(e => e.id === id);
        const toastListItem = toastList.findIndex(e => e.id === id);
        if (toastList[toastListItem].substring(0,9) !== "PERMANENT") {
            list.splice(listItemIndex, 1);
            toastList.splice(toastListItem, 1);
            setList([...list]);
        }
    }

    return (
        <div className="toaster">
            {list.map((toast, i) => 
                <div className="toast" key={i}>{(toast.substring(0,9) === 'PERMANENT') ? toast.substring(9) : toast}</div>
            )}
        </div>
    )
}