import { useState, useEffect } from "react";

export default function Modal({show, onClose, mode, game, win, id, inds, statistics, row, addToast, shareMessage}) {
    const [darkmode, setDarkMode] = useState((JSON.parse(localStorage.getItem('darkmodeLocal')) !== null) ? JSON.parse(localStorage.getItem('darkmodeLocal')) : true);
    const [colorblind, setColorblind] = useState((JSON.parse(localStorage.getItem('colorblindLocal')) !== null) ? JSON.parse(localStorage.getItem('colorblindLocal')) : false);
    const [remTime, setRemTime] = useState('00:00:00');

    useEffect(() => {
        setInterval(() => {
            setRemTime((new Date(((24*60*60) - ((new Date()).getHours()*60*60) - ((new Date()).getMinutes()*60) - (new Date()).getSeconds()) * 1000)).toISOString().substring(11,19));
        }, 1000);
    }, []);

    useEffect(() => {
        document.body.classList = [""];
        if (darkmode) {document.body.classList.add('darkmode')}
        if (colorblind) document.body.classList.add('colorblind')
        localStorage.setItem('darkmodeLocal', darkmode);
        localStorage.setItem('colorblindLocal', colorblind);
    }, [darkmode, colorblind])

    function share() {
        const size = row + 1;
        navigator.clipboard.writeText("Wordle " + id + " " + size + "/6\n\n" + inds.slice(0,size).map(tileRow => tileRow.map(tile => (tile === 2) ? String.fromCodePoint(0x2B1B) : ((tile === 3) ? String.fromCodePoint(0x1F7E8) : ((tile === 4) ? String.fromCodePoint(0x1F7E9) : tile ))).join('')).join('\n'))
        addToast(shareMessage);
    }

    return (
        <div className={['modal',show ? ' show' : null].join('')} onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-close" onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                        <path fill="var(--menu)" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                    </svg>
                </div>
                {(mode === "settings") 
                        ?
                    <div className="settings">
                        <h1>Settings</h1>
                        <div className="setting">
                            <div className="setting-text">
                                <div className="setting-title">Dark Theme</div>
                            </div>
                            <div className="control">
                                <div className="switch" onClick={() => (darkmode) ? setDarkMode(false) : setDarkMode(true)} style={(darkmode) ? {backgroundColor: "var(--green)"} : null}>
                                    <span className="knob" style={(darkmode) ? {transform: "translateX(12px)"} : null}></span>
                                </div>
                            </div>
                        </div>
                        <div className="setting">
                            <div className="setting-text">
                                <div className="setting-title">Colorblind Mode</div>
                                <div className="setting-description">High Contrast Colors</div>
                            </div>
                            <div className="control">
                                <div className="switch" onClick={() => (colorblind) ? setColorblind(false) : setColorblind(true)} style={(colorblind) ? {backgroundColor: "var(--green)"} : null}>
                                <span className="knob" style={(colorblind) ? {transform: "translateX(12px)"} : null}></span>
                                </div>
                            </div>
                        </div>
                        <div className="setting">
                            <div className="setting-text">
                                <div className="setting-title">Feedback</div>
                            </div>
                            <div className="control">
                                <a href="mailto:wordle@powerlanguage.co.uk?subject=Feedback" title="wordle@powerlanguage.co.uk">Email</a>
                                <span style={{margin: "0 4px"}}>|</span>
                                <a href="https://twitter.com/intent/tweet?screen_name=powerlanguish" target="blank" title="@powerlanguish">Twitter</a>
                            </div>
                        </div>
                        <div className="footer">
                            <div className="copyright">Copyright 2022. All Rights Reserved.</div>
                            <div>
                                <div className="puzzle-number">#{id}</div>
                            </div>
                        </div>
                    </div>
                        : (mode === "instructions") ?
                    <div className="instructions">
                        <p>Guess the <strong>WORDLE</strong> in 6 tries.</p>
                        <p>Each guess must be a valid 5 letter word. Hit the enter button to submit.</p>
                        <p>After each guess, the color of the tiles will change to show how close your guess was to the word.</p>
                        <div className="examples">
                            <p><strong>Examples</strong></p>
                            <div className="example">
                            <div className="example-row">
                                <div className="example-tile" style={{backgroundColor: 'var(--green)', color: 'var(--font-reverse)', border: 'none'}}>w</div>
                                <div className="example-tile">e</div>
                                <div className="example-tile">a</div>
                                <div className="example-tile">r</div>
                                <div className="example-tile">y</div>
                            </div>
                            <p>The letter <strong>W</strong> is in the word and in the correct spot.</p>
                            </div>
                            <div className="example">
                            <div className="row">
                                <div className="example-tile">p</div>
                                <div className="example-tile" style={{backgroundColor: 'var(--yellow)', color: 'var(--font-reverse)' , border: 'none'}}>i</div>
                                <div className="example-tile">l</div>
                                <div className="example-tile">l</div>
                                <div className="example-tile">s</div>
                            </div>
                            <p>The letter <strong>I</strong> is in the word but in the wrong spot.</p>
                            </div>
                            <div className="example">
                            <div className="row">
                                <div className="example-tile">v</div>
                                <div className="example-tile">a</div>
                                <div className="example-tile">g</div>
                                <div className="example-tile" style={{backgroundColor: 'var(--gray)', color: 'var(--font-reverse)', border: 'none'}}>u</div>
                                <div className="example-tile">e</div>
                            </div>
                            <p>The letter <strong>U</strong> is not in the word in any spot.</p>
                            </div>
                        </div>
                        <p><strong>A new WORDLE will be available each day!</strong></p>
                    </div>
                        : 
                    <div className="statistics-container">
                        <h1>Statistics</h1>
                        <div className="statistics">
                            <div className="statistic-container">
                                <div className="statistic">{statistics.gamesPlayed}</div>
                                <div className="label">Played</div>
                            </div>
                            <div className="statistic-container">
                                <div className="statistic">{(statistics.gamesPlayed !== 0) ? Math.round((statistics.gamesWon / statistics.gamesPlayed) * 100) : 0}</div>
                                <div className="label">Win %</div>
                            </div>
                            <div className="statistic-container">
                                <div className="statistic">{statistics.currentStreak}</div>
                                <div className="label">Current Streak</div>
                            </div>
                            <div className="statistic-container">
                                <div className="statistic">{statistics.maxStreak}</div>
                                <div className="label">Max Streak</div>
                            </div>
                        </div>
                        <h1>Guess Distribution</h1>
                        <div className="guess-distribution">
                            {(statistics.gamesPlayed !== 0) ?
                            <div className="graphs">
                                {Array.from({length: 6}, (_, i) => i + 1).map((guess ,g) => 
                                    <div className="graph-container" key={g}>
                                        <div className="guess">{guess}</div>
                                        <div className="graph">
                                            <div className={["graph-bar", (statistics['guesses'][String(guess)] !== 0) ? " align-right" : null, (!game && win && row === guess - 1) ? " highlight" : null].join('')} style={{width: (statistics['guesses'][String(guess)] !== 0) ? Math.round(((statistics['guesses'][String(guess)])/Math.max.apply(0, Object.values(statistics['guesses'])))*100) + '%' : "7%"}}>
                                                <div className="num-guesses">{statistics['guesses'][String(guess)]}</div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            : <div className="no-data">No Data</div>
                            }
                        </div>
                        {(!game) ? 
                        <div className="statistics-footer">
                            <div className="countdown">
                                <h1>Next Wordle</h1>
                                <div className="timer">
                                    <div className="statistic-container">
                                        <div className="statistic">{remTime}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="share">
                                <button className="share-button" onClick={() => share()}>
                                    <span style={{margin: "0 8px"}}>Share</span> 
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                                        <path fill="#ffffff" d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92c0-1.61-1.31-2.92-2.92-2.92zM18 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM6 13c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm12 7.02c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                            : null
                        }
                    </div>
                }
            </div>
        </div>
    )
}