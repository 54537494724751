import { useState, useEffect } from "react";
import axios from 'axios';
import Modal from './Modal'
import Toaster from './Toaster'

export default function Game() {
  const prev = "https://wordle.aditzak.eus/server";
  const [dictionary, setDictionary] = useState([]);
  const [word, setWord] = useState(null);
  const [wordID, setWordID] = useState(null);

  useEffect(() => {
    async function fetchWord() {
      const response = await axios.get(prev + "/getword.php?date=" + ((new Date()).toLocaleDateString('pt-br').split( '/' ).reverse( ).join( '-' )));
      setWord(response.data[0].word);
      setWordID(response.data[0].id);
    }
    async function fetchDictionary() {
      const response = await axios.get(prev + "/getdictionary.php");
      setDictionary(response.data);
    }
    fetchWord();
    fetchDictionary();
  }, [])

  const messages = {
    notInList: "Not in word list",
    notEnough: "Not enough letters",
    success: ["Genius", "Magnificient", "Impressive", "Splendid" ,"Great", "Phew"],
    share: "Results shared to clipboard"
  };
  
  function gameExist() {
    if (JSON.parse(localStorage.getItem('gameStateLocal')) !== null) {
      return JSON.parse(localStorage.getItem('gameStateLocal')).last === (new Date()).toLocaleDateString('pt-br').split( '/' ).reverse( ).join( '-' );
    }
    return false;
  }

  const keyList = [['Q','W','E','R','T','Y','U','I','O','P',],['Spacer','A','S','D','F','G','H','J','K','L','Spacer',],['Enter','Z','X','C','V','B','N','M','Back']];
  const [modalMode, setModalMode] = useState("statistics");
  const [modalShow, setModalShow] = useState(false);
  const [toastList, setToastList] = useState([]);
  const [statistics, setStatistics] = useState((JSON.parse(localStorage.getItem('statisticsLocal')) !== null) ? JSON.parse(localStorage.getItem('statisticsLocal')) : {currentStreak: 0, maxStreak: 0, guesses: {1:0,2:0,3:0,4:0,5:0,6:0}, gamesPlayed: 0, gamesWon: 0});
  const [game, setGame] = useState((gameExist()) ? JSON.parse(localStorage.getItem('gameStateLocal')).game : true);
  const [win, setWin] = useState((gameExist()) ? JSON.parse(localStorage.getItem('gameStateLocal')).win : false);
  const [tiles, setTiles] = useState((gameExist()) ? JSON.parse(localStorage.getItem('gameStateLocal')).tiles : [['','','','',''],['','','','',''],['','','','',''],['','','','',''],['','','','',''],['','','','','']]);
  const [tileStyles, setTileStyles] = useState((gameExist()) ? JSON.parse(localStorage.getItem('gameStateLocal')).tileStyles : [[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0]]);
  const [keyStyles, setKeyStyles] = useState((gameExist()) ? JSON.parse(localStorage.getItem('gameStateLocal')).keyStyles : [[0,0,0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0,0]]);
  const [row, setRow] = useState((gameExist()) ? JSON.parse(localStorage.getItem('gameStateLocal')).row : 0);
  const [col, setCol] = useState((gameExist()) ? JSON.parse(localStorage.getItem('gameStateLocal')).col : 0);
  const [tileAnims, setTileAnims] = useState([["", "", "", "", ""],["", "", "", "", ""],["", "", "", "", ""],["", "", "", "", ""],["", "", "", "", ""],["", "", "", "", ""]]);
  const [rowAnims, setRowAnims] = useState(["","","","","",""])

  function updateStatistics(win, guesses) {
    let sT = statistics;
    sT.gamesPlayed += 1;
    if (win) {
      sT.gamesWon += 1;
      if (sT.currentStreak === sT.maxStreak) sT.maxStreak += 1;
      sT.currentStreak += 1;
      sT['guesses'][String(guesses)] += 1;
    }
    else {
      sT.currentStreak = 0; 
    }
    setStatistics(sT);
    localStorage.setItem('statisticsLocal', JSON.stringify(statistics));
  }

  function addToast(toast) {
    setToastList(toastList => [...toastList,toast] );
  }

  function indicateRow(row) {
    let s = tileStyles;
    let k = keyStyles;
    for (let i = 0; i <= 4; i++) {
      if (tiles[row][i].toLowerCase() === word[i]) { 
        s[row][i] = 4;
        keyList.forEach((keyRow, j) => {
          if (keyRow.indexOf(tiles[row][i]) !== -1) {
            k[j][keyRow.indexOf(tiles[row][i])] = 3;
          }
        })
      }
      else if (word.includes(tiles[row][i].toLowerCase())) { 
        s[row][i] = 3;
        keyList.forEach((keyRow, j) => {
          if (keyRow.indexOf(tiles[row][i]) !== -1) {
            if (k[j][keyRow.indexOf(tiles[row][i])] !== 3) { k[j][keyRow.indexOf(tiles[row][i])] = 2 }
          }
        })
      }
      else { 
        s[row][i] = 2;
        keyList.forEach((keyRow, j) => {
          if (keyRow.indexOf(tiles[row][i]) !== -1) {
            if (k[j][keyRow.indexOf(tiles[row][i])] !== 3 & k[j][keyRow.indexOf(tiles[row][i])] !== 2) { k[j][keyRow.indexOf(tiles[row][i])] = 1 }
          }
        })
      }
    }
  }

  async function postStats(win) {
    let body = {};
    if (win) {
      body.win = 1;
      body.tries = row + 1;
    }
    else {
      body.win = 0;
      body.tries = 0;
    }
    await axios.put(prev + "/putstats.php?id=" + wordID, body);
  }

  function pressKey(value) {
    if (game === true) {
      if (value === "Back") {
        if (col > 0) {
          let t = tiles;
          let s = tileStyles;
          t[row][col - 1] = '';
          s[row][col - 1] = 0;
          setTiles(t);
          setTileStyles(s);
          setCol(col - 1);
        }
      }
      else if (value === "Enter") {
        if (tiles[row].every(element => element !== '')) {
          if (tiles[row].join('').toLowerCase() !== word) {
            if (dictionary.includes(tiles[row].join('').toLowerCase())) {
              if (row < 5) {
                indicateRow(row);
                setRow(row + 1);
                setCol(0);
              }
              else {
                indicateRow(row);
                updateStatistics(false, 0);
                postStats(false);
                addToast('PERMANENT' + word.toUpperCase());
                setGame(false);
                showModal('statistics');
              }
            }
            else {
              addToast(messages.notInList);
            }
          }
          else {
            indicateRow(row);
            setGame(false);
            setWin(true);
            postStats(true);
            updateStatistics(true, row + 1);
            switch(row) {
              case 0:
                addToast(messages.success[0]);
                break;
              case 1:
                addToast(messages.success[1]);
                break;
              case 2:
                addToast(messages.success[2]);
                break;
              case 3:
                addToast(messages.success[3]);
                break;
              case 4:
                addToast(messages.success[4]);
                break;
              case 5:
                addToast(messages.success[5]);
                break;
              default:
            }
            showModal('statistics');
          }
        }
        else {
          addToast(messages.notEnough);
        }
      }
      else {
        if (col < 5) {
          let t = tiles;
          let s = tileStyles;
          let a = tileAnims;
          a[row][col] = 'pop';
          t[row][col] = value;
          s[row][col] = 1;
          setCol(col + 1);
        }
      }
    }
  }

  function showModal(mode) {
    setModalMode(mode);
    setModalShow(true);
  }

  useEffect(() => {
    if (gameExist()) if (!JSON.parse(localStorage.getItem('gameStateLocal')).game) showModal('statistics')
  }, [])

  useEffect(() => {
    const gameStateLocal = {game: game, tiles: tiles, win: win, tileStyles: tileStyles, keyStyles: keyStyles, row: row, col: col, last: ((new Date()).toLocaleDateString('pt-br').split( '/' ).reverse( ).join( '-' ))};
    localStorage.setItem('gameStateLocal', JSON.stringify(gameStateLocal));
  }, [game, win, tiles, tileStyles, keyStyles, row, col]);

  useEffect(() => {
    localStorage.setItem('statisticsLocal', JSON.stringify(statistics));
  }, [statistics]);

  useEffect(() => {
    const handleKeyInput = (event) => {
      if ((event.keyCode >= 65 & event.keyCode <= 90) || event.keyCode === 13 || event.keyCode === 8) {
        pressKey((event.keyCode === 13) ? "Enter" : ((event.keyCode === 8) ? "Back" : String.fromCharCode(event.keyCode)));
      }
    };
    window.addEventListener('keydown', handleKeyInput);
    return () => {
      window.removeEventListener('keydown', handleKeyInput);
    };
  }, [col, game]);

  return (
    (word === null) ? <span></span> :
    <div className="game">
      <div className="header">
        <div className="menu">
            <button className="option-button" aria-label="help" onClick={() => showModal("instructions")}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                    <path fill="var(--menu)" d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path>
                </svg>
            </button>
        </div>
        <div className="title">WORDLE</div>
        <div className="menu">
            <button className="option-button" aria-label="statistics" onClick={() => showModal("statistics")}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                    <path fill="var(--menu)" d="M16,11V3H8v6H2v12h20V11H16z M10,5h4v14h-4V5z M4,11h4v8H4V11z M20,19h-4v-6h4V19z"></path>
                </svg>
            </button>
            <button className="option-button" aria-label="settings" onClick={() => showModal("settings")}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                    <path fill="var(--menu)" d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"></path>
                </svg>
            </button>
        </div>
      </div>
      <div className="container" style={{minHeight: window.innerHeight - 250}}>
        <div className="board">
        {[...Array(6).keys()].map((tileRow) =>
            <div className="tile-row" key={tileRow} data-animation={rowAnims[tileRow]} onAnimationEnd={() => {setRowAnims(['','','','','',''])}}>
              {[...Array(5).keys()].map((tile) =>
                <div className={"tile state" + tileStyles[tileRow][tile]} key={tile} data-animation={tileAnims[tileRow][tile]} onAnimationEnd={() => {let a = tileAnims; a[tileRow][tile] = ""; setTileAnims(a)}}>{tiles[tileRow][tile]}</div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="keyboard">
        {keyList.map((keyRow, r) => 
          <div className="key-row" key={r}>
            {keyRow.map((key, k) => 
              (key === "Spacer") ? 
                <div style={{flex: "0.5"}} key={k}></div>
                  : ((key === "Enter" || key === "Back") ? ((key === "Back") ? 
                <button className="key" style={{flex: "1.5", fontSize: "12px"}} onClick={() => pressKey('Back')} key={k}>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                    <path fill="var(--font)" d="M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.9.89 1.59.89h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H7.07L2.4 12l4.66-7H22v14zm-11.59-2L14 13.41 17.59 17 19 15.59 15.41 12 19 8.41 17.59 7 14 10.59 10.41 7 9 8.41 12.59 12 9 15.59z"></path>
                  </svg>
                </button>
                  : 
                <button className="key" style={{flex: "1.5", fontSize: "12px"}} key={k} onClick={() => pressKey('Enter')}>Enter</button>
                  ): 
                <button className={"key state" + keyStyles[r][k]} onClick={() => pressKey(key)} key={k}>{key}</button>
              )
            )}
          </div>
        )}
      </div>
      <Modal show={modalShow} onClose={() => setModalShow(false)} mode={modalMode} statistics={statistics} id={wordID} game={game} win={win} inds={tileStyles} row={row} addToast={addToast} shareMessage={messages.share}/>
      <Toaster toastList={toastList} />
    </div>
  );
}